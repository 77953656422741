import { encodeFileName } from "helpers/general";
import { APIClient } from "./axiosClient";
import { IPopupDTO } from "./types/_cmsHub";
import { QueryParams } from "components/Hooks/useQueryParams";
const api = new APIClient();

const path = "/cmshub";
const cmsHubMediaApi = {
  cmshubCompanies(dataForm: QueryParams) {
    return api.get(`${path}/companies`, dataForm);
  },
  cmshubHomepage(dataForm: QueryParams) {
    return api.get(`${path}/homepages`, dataForm);
  },
  cmshubHomepageSumary(dataForm: QueryParams) {
    return api.get(`${path}/homepages/summary`, dataForm);
  },
  cmshubPopups(dataForm: QueryParams) {
    return api.get(`${path}/popups`, dataForm);
  },
  cmshubUpdatePopups(
    params: IPopupDTO,
    attachments: Array<any>,
    company_id: number,
    popup_id: number
  ) {
    const formData = new FormData();

    formData.append("title", params.title);
    formData.append("status", params.status.toString());

    if (params.valid_from) {
      formData.append("valid_from", params.valid_from);
    }

    if (params.valid_to) {
      formData.append("valid_to", params.valid_to);
    }

    if (attachments && attachments.length > 0) {
      attachments.forEach((file: any) => {
        formData.append("media_ids[]", file.id ? file.id : "null");
        !file.id &&
          formData.append(`mapping_attactments[]`, encodeFileName(file));
      });
    }

    formData.append("link_url", params.link_url);
    formData.append("width", params.width.toString());
    formData.append("height", params.height.toString());
    formData.append("position_x", params.position_x.toString());
    formData.append("position_y", params.position_y.toString());
    formData.append("is_show_close", params.is_show_close.toString());
    formData.append(
      "is_show_close_today",
      params.is_show_close_today.toString()
    );
    return api.put(`${path}/popups/${company_id}/${popup_id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-ms-blob-type": "BlockBlob",
      },
    });
  },
  cmshubPopup(company_id: number, popup_id: number) {
    return api.get(`${path}/popups/${company_id}/${popup_id}`);
  },
  cmshubDeletePopups(company_id: number, popup_id: number) {
    return api.delete(`${path}/popups/${company_id}/${popup_id}`);
  },
  cmshubReporters(dataForm: QueryParams) {
    return api.get(`${path}/reporters`, dataForm);
  },
  cmshubDetailReporter(id: number) {
    return api.get(`${path}/reporters/${id}`);
  },
  cmshubCreateMedia(formData: any) {
    return api.post(`${path}/media`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default cmsHubMediaApi;
